// CUSTOM JavaScript
import './jquery-global.js';
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle';

// Form Select Styling
$('.select[name=role_title]').change(function () {
	$(this.value === 'role' ? '' : $(this).css('color', '#8E323D'));
});

// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .not('[href="#carouselExampleControls"]')
  .click(function (event) {
    // On-page links
	if (
		location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
		location.hostname === this.hostname
	) {
      // Figure out element to scroll to
		let target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
		// Does a scroll target exist?
		if (target.length !== 0) {
        // Only prevent default if animation is actually gonna happen
			event.preventDefault();
			$('html, body').animate({
				scrollTop: target.offset().top - 200
			}, 1000, function () {
          // Callback after animation
          // Must change focus!
				let $target = $(target);
				$target.focus();
				if ($target.is(':focus')) { // Checking if the target was focused
					return false;
				}
				$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
				$target.focus(); // Set focus again
			});
		}
	}
});
